import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import BackgroundImage from "gatsby-background-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import ModalGallery from "../components/Modal/ModalGallery";

const Page = ({ data }) => {
   const [slideIndex, setSlideIndex] = useState(0);

   const processBgImages = [
      data.processBgDesktop.childImageSharp.fixed,
      {
         ...data.processBgDesktop.childImageSharp.fixed,
         media: `(max-width: 767px)`,
      },
   ];

   const url = typeof window !== "undefined" ? window.location.pathname : "";

   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Tooling, Mold Making, & Metal Work | Painted Rhino"
            description="Painted Rhino takes simple processes and elevates them into an art form. We bring your concept to life with our tooling, mold making and metal work. "
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <section className="pt-6 md:pt-14 pb-20 md:pb-32">
            <div className="container">
               <header className="mb-12">
                  <h1>Tooling, Mold Making & Metal Work</h1>
               </header>

               <div className="mb-12 md:mb-28">
                  <Img fadeIn={false} loading="eager" fluid={data.heroDesktop.childImageSharp.fluid} alt="Fiberglass & Composites" />
               </div>

               <div className="grid grid-cols-1 md:grid-cols-12 md:gap-x-20">
                  <div className="md:col-start-1 md:col-span-6">
                     <h2>Professional Mold Making Company</h2>
                  </div>
                  <div className="md:col-end-13 md:col-span-6">
                     <p className="mb-0">
                        Our team at Painted Rhino takes simple processes and elevates them into an art form. We take your concept and bring it to life
                        with our tooling, mold making and metal work. Tooling involves sourcing materials and engineering tools to manufacture
                        components. Our manufacturing company also offers custom mold making and metal work to produce anything for your needs. We
                        combine state of the art machinery and the human touch to turn your concept and turn them into solutions for your industry
                        needs. Work with Painted Rhino to get the best possible product at quick turnaround times.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <BackgroundImage fixed={processBgImages} className="w-full pt-22 md:pt-32 pb-18 md:pb-36">
            <div className="container relative">
               <header className="mb-18 md:mb-22 max-w-sm">
                  <h3>Our Tooling, Mold Making, & Metal Work Processes</h3>
               </header>

               <ScrollWrapper>
                  <ScrollNavigation>
                     <li>
                        <AnchorLink to={url + "#section-1"} title="Tooling" stripHash />
                     </li>
                     <li>
                        <AnchorLink to={url + "#section-2"} title="Mold Making Services" stripHash />
                     </li>
                     <li>
                        <AnchorLink to={url + "#section-3"} title="Metal Work" stripHash />
                     </li>
                  </ScrollNavigation>

                  <ScrollContent>
                     <div id="section-1" className="md:bg-white md:pt-14 md:pb-12 md:px-14 mb-12 lg:mb-20">
                        <h4>Tooling</h4>
                        <p className="mb-0">
                           The best parts require the best tools. In the composite and Vacuum form industry, “tooling” can also refer to the mold your
                           part is produced out of. Your part is usually only as good as your mold (tool). We can help design and engineer quality
                           molds (tools), cutting tools for grinding and milling, jigs, fixtures forging machines, and other tools for precise
                           production. Painted Rhino not only engineers quality tools but we also select the right process and tool for your project.
                           We take into account the volume, the materials to be used, and other factors that impact the end product’s quality.
                        </p>
                     </div>

                     <div id="section-2" className="md:bg-white md:pt-14 md:pb-12 md:px-14 mb-12 lg:mb-20">
                        <h4>Vacuum Assisted Resin Transfer Molding</h4>
                        <p className="mb-0">
                           Painted Rhino creates molds for a variety of applications. We produce and supply FRP parts using open or closed mold
                           injection processes. Our capabilities also include <a href="https://www.paintedrhino.com/vacuum-forming-thermoforming-company/" className="text-[#006ee8]">vacuum forming and thermoforming</a>, <a href="https://www.paintedrhino.com/injection-urethane-rotocasting/" className="text-[#006ee8]">injection urethane</a>, <a href="https://www.paintedrhino.com/injection-urethane-rotocasting/" className="text-[#006ee8]">rotocasting</a>, and
                           more.
                        </p>
                     </div>

                     <div id="section-3" className="md:bg-white md:pt-14 md:pb-12 md:px-14">
                        <h4>Metal Work</h4>
                        <p className="mb-0">
                           Manufacture a wide variety of products and parts through metal fabrication. We combine automated processes, and manual
                           labor to produce your custom project. Our team uses different techniques to cut, shape, and mold metal for commercial,
                           industrial, and structural applications.
                        </p>
                     </div>
                  </ScrollContent>
               </ScrollWrapper>
            </div>
         </BackgroundImage>

         <section className="py-20 md:py-32">
            <div className="container">
               <div className="grid md:grid-cols-2 gap-2 md:gap-4">
                  <div className="grid grid-rows-2 grid-cols-10 gap-2 md:gap-4">
                     <div className="col-start-1 col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(0)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail1.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-end-11 col-span-4 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(1)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail2.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-start-1 col-span-4 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(2)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail3.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-end-11 col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(3)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail4.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
                  <div className="grid grid-rows-2 grid-cols-10 grid-flow-col gap-2 md:gap-4">
                     <div className="col-span-4 row-span-2">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(4)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail5.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(5)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail6.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="row-span-1 col-span-6">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(6)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail7.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <TestimonialBackgroundImage />

         <WhyUs
            className="pt-22 md:pt-32 pb-20 md:pb-28"
            contentAlignment="md:text-center md:mx-auto"
            headerHeading="The Painted Rhino Difference."
         />

         <CTABackgroundImage />

         <ModalGallery slideIndex={slideIndex}>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox1.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox2.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox3.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox4.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox5.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox6.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox7.childImageSharp.fluid} alt="" />
            </div>
         </ModalGallery>
      </Layout>
   );
};

export default Page;

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Tooling, Mold Making _ Metal Work_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Tooling, Mold Making _ Metal Work_Twitter.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "services/tooling-mold-making-metal-work.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2400) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      processBgDesktop: file(relativePath: { eq: "services/process-bg-desktop.jpg" }) {
         childImageSharp {
            fixed(width: 2880, height: 1432) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      galleryThumbnail1: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Thumbnails/Top-left.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 355) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox1: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Lightbox/steel works interior.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail2: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Thumbnails/Top-middle.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 221) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox2: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Lightbox/IMG_0490.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail3: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Thumbnails/Bottom-left.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox3: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Lightbox/IMG_0245.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail4: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Thumbnails/Bottom-middle.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox4: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Lightbox/steel works.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail5: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Thumbnails/Middle.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox5: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Lightbox/IMG_4568.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail6: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Thumbnails/Top-right.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox6: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Lightbox/IMG_0783.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail7: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Thumbnails/Bottom-right.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox7: file(relativePath: { eq: "services/Tooling-Mold-Making-Metal-Work/Gallery/Lightbox/IMG_1337.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
   }
`;
